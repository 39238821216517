import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import {Spread, SpreadResponse} from "../interfaces/Spread/spread";
import style from "../../components/PortfolioWrapper/Portfolio.module.scss";


export const formatDate = (date: string): string => {
  const timestamp = moment(date);
  const now = moment();
  const duration = moment.duration(now.diff(timestamp));

  const secondsAgo = duration.asSeconds();
  const minutesAgo = duration.asMinutes();

  if (secondsAgo < 60) {
    return `${Math.floor(secondsAgo)}s ago`;
  } else {
    return `${Math.floor(minutesAgo)}m ago`;
  }
};

export const formatedNumber = (number: any) => {
    const balance = parseFloat(number);
    const result = number && number.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return balance < 0 ? result.substring(1) : result;
}

export const determineStateBalance = (number: any) => {
    const balance = parseFloat(number);
    return balance < 0 ? '-' : '+';
}

export const generateId = (min: number = 1, max: number = 100000) => {
    return uuidv4();
}

export const filterSpreads = (response: any): Spread[] => {
    const mergedData: Spread[] = [];
    Object.keys(response.pinned).forEach((coin: any) => {
        const coinData = response.pinned[coin];
        const coinSpread: Spread = {
            id: [],
            coin: coin,
            percent: [],
            exchangeBuy: [],
            balance: [],
            chain: [],
            commission: [],
            exchangeSell: [],
            buyPrice: [],
            income: [],
            totalBuyPrice: [],
            totalIncome: [],
            created: [],
            updated: [],
            pinned: true,
        };

        coinData.forEach((spread: any) => {
            coinSpread.id.push(spread.id);
            coinSpread.percent.push(spread.percent + '%');
            coinSpread.exchangeBuy.push({
                exchangeBuy: spread.exchangeBuy,
                exchangeBuyTradeLink: spread.exchangeBuyTradeLink,
                exchangeBuyWithdrawLink: spread.exchangeBuyWithdrawLink,
                exchangeBuyPairTradeLink: spread.exchangeBuyPairTradeLink,
                withdrawTips: spread.withdrawTips,
                isAnotherPair: spread.isAnotherPair
            });
            coinSpread.balance.push(spread.usdtBalance);
            coinSpread.chain.push({
                chain: spread.chain,
                sameContract: spread.sameContract,
                chainAverageTime: spread.chainAverageTime,
                depositConfirm: spread.depositConfirm,
            });
            coinSpread.commission.push({
                commissionValue: '~ ' + spread.commission + '$',
                checkCommission: spread.checkCommission
            });
            coinSpread.exchangeSell.push({
                exchangeSell: spread.exchangeSell,
                exchangeSellTradeLink: spread.exchangeSellTradeLink,
                exchangeSellDepositLink: spread.exchangeSellDepositLink,
                exchangeSellPairTradeLink: spread.exchangeSellPairTradeLink,
                depositTips: spread.depositTips
            });
            coinSpread.buyPrice.push('~ ' + spread.buyPrice + '$');
            coinSpread.income.push('~ ' + spread.income + '$');
            coinSpread.totalBuyPrice.push('~ ' + spread.totalBuyPrice + '$');
            coinSpread.totalIncome.push('~ ' + spread.totalIncome + '$');
            coinSpread.created.push(formatDate(spread.created));
            coinSpread.updated.push(formatDate(spread.updated));
        });

        mergedData.push(coinSpread);
    });
    Object.keys(response.nonPinned).forEach((coin: any) => {
        const coinData = response.nonPinned[coin];
        const coinSpread: Spread = {
            id: [],
            coin: coin,
            percent: [],
            exchangeBuy: [],
            balance: [],
            chain: [],
            commission: [],
            exchangeSell: [],
            buyPrice: [],
            income: [],
            totalBuyPrice: [],
            totalIncome: [],
            created: [],
            updated: [],
            pinned: false
        };

        coinData.forEach((spread: any) => {
            coinSpread.id.push(spread.id);
            coinSpread.percent.push(spread.percent + '%');
            coinSpread.exchangeBuy.push({
                exchangeBuy: spread.exchangeBuy,
                exchangeBuyTradeLink: spread.exchangeBuyTradeLink,
                exchangeBuyWithdrawLink: spread.exchangeBuyWithdrawLink,
                exchangeBuyPairTradeLink: spread.exchangeBuyPairTradeLink,
                withdrawTips: spread.withdrawTips,
                isAnotherPair: spread.isAnotherPair
            });
            coinSpread.balance.push(spread.usdtBalance);
            coinSpread.chain.push({
                chain: spread.chain,
                sameContract: spread.sameContract,
                chainAverageTime: spread.chainAverageTime,
                depositConfirm: spread.depositConfirm,
            });
            coinSpread.commission.push({
                commissionValue: '~ ' + spread.commission + '$',
                checkCommission: spread.checkCommission
            });
            coinSpread.exchangeSell.push({
                exchangeSell: spread.exchangeSell,
                exchangeSellTradeLink: spread.exchangeSellTradeLink,
                exchangeSellDepositLink: spread.exchangeSellDepositLink,
                exchangeSellPairTradeLink: spread.exchangeSellPairTradeLink,
                depositTips: spread.depositTips
            });
            coinSpread.buyPrice.push('~ ' + spread.buyPrice + '$');
            coinSpread.income.push('~ ' + spread.income + '$');
            coinSpread.totalBuyPrice.push('~ ' + spread.totalBuyPrice + '$');
            coinSpread.totalIncome.push('~ ' + spread.totalIncome + '$');
            coinSpread.created.push(formatDate(spread.created));
            coinSpread.updated.push(formatDate(spread.updated));
        });

        mergedData.push(coinSpread);
    });
    return mergedData;
}

export const getSpreadsLength = (spreads: Record<string, SpreadResponse[]>): number => {
    const spreadsLength = Object.keys(spreads).length;
    return spreadsLength;
}