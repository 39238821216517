import React, {useState} from 'react';
import styles from './Modal.module.scss';
import {getAuth} from "../helpers/auth";
import axios from "axios";
import {BASE_URL} from "../api/api";

const Modal = ({show, onClose, asset, fetchData}: any) => {
    const [apiKey, setApiKey] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [passphrase, setPassphrase] = useState('');

    const handleApiKeyChange = (e:any) => setApiKey(e.target.value);
    const handleSecretKeyChange = (e:any) => setSecretKey(e.target.value);
    const handlePassphraseChange = (e:any) => setPassphrase(e.target.value);

    const handleSubmit = async () => {
        const payload = {
            exchange: asset.exchange,
            apiKey: apiKey,
            secretKey: secretKey,
            ...(showPassphrase && {passphrase: passphrase})
        };

        const headers = getAuth().headers;

        try {
            await axios.put(`${BASE_URL}/apiKey`, payload, {headers});
            console.log('Success:', payload);
            fetchData();
            onClose();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const showPassphrase: any = asset && (asset.exchange === 'bitget' || asset.exchange === 'kucoin');
    if (!asset) return null;
    return (
        <>
            {asset &&
              <div className={`${styles.modalWindow} ${show ? styles.show : ''}`}>
                <div className={styles.modalTitle}>
                  <h3>Add API key for {asset.exchange}</h3>
                    <span className={styles.modalClose} onClick={onClose}>X</span>
                    {/*<a href="#" className={styles.modalClose} onClick={onClose}>Закрыть</a>*/}
                </div>
                <div className={styles.items}>
                  <div className={styles.item}>
                    <span>API Key</span>
                    <input value={apiKey} onChange={handleApiKeyChange} type='text'/>
                  </div>
                  <div className={styles.item}>
                    <span>Secret Key</span>
                    <input value={secretKey} onChange={handleSecretKeyChange} type='text'/>
                  </div>
                    {showPassphrase && (
                        <div className={styles.item}>
                            <span>Passphrase Key</span>
                            <input value={passphrase} onChange={handlePassphraseChange} type="text"/>
                        </div>
                    )}
                </div>
                <div className={styles.btnWrapper}>
                  <button className={styles.btn} onClick={handleSubmit}>Apply</button>
                </div>
              </div>
            }
            <div className={`${styles.modalBackdrop} ${show ? '' : styles.hidden}`} onClick={onClose}></div>
        </>
    );
};

export default Modal;