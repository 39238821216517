import React from 'react';
import {generateId} from "../../common/helpers/helpers";
import style from "../../components/SpreadTable/SpreadTable.module.scss";

interface BuyPriceProps {
    spread: {
        buyPrice: string[]
    }
}

const BuyPrice: React.FC<BuyPriceProps> = ({spread}): React.ReactElement => {
    return (
        <td>{spread.buyPrice.map((buyPrice) => (
            <div key={generateId()} className={style.lastSpread}>
                <div>{buyPrice}</div>
            </div>
        ))}</td>
    );
};

export default BuyPrice;