import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../common/api/api";
import { NotificationManager } from "react-notifications";
import {getAuth} from "../../common/helpers/auth";

interface UserState {
  status: 'none' | 'loading' | 'succeed' | 'rejected';
  error: string | null;
  profile: any;
}

interface Passwords {
  oldPassword: string;
  newPassword: string;
}

export const getProfile = createAsyncThunk('user/profile', async () => {
  try {
    const response = await axios.get(`${BASE_URL}/user/profile`, getAuth());
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    NotificationManager.error(error.response.data.message);
    throw error;
  }
});

export const changePassword = createAsyncThunk('user/changePassword', async ({oldPassword, newPassword}: Passwords) => {
  try {
    const postData = { oldPassword, newPassword };
    const response = await axios.post(`${BASE_URL}/user/changePassword`, postData, getAuth());

    if (response.status === 200) {
      NotificationManager.success('Password changed successful');
      return response.data.status;
    }
  } catch (error: any) {
    NotificationManager.error(error.response.data.message);
    throw error;
  }
});

const userSlice = createSlice<UserState, Record<string, (state: UserState, action: PayloadAction<any>) => void>>({
  name: 'user',
  initialState: {
    status: 'none',
    error: null,
    profile: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.profile = action.payload;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Authentication failed';
      })
  }
});

export const selectGetProfile = (state: { profile: UserState }) => state.profile.profile;

export default userSlice.reducer;
