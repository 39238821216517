import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale } from 'chart.js';
import styles from './Balance.module.scss';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

const BalanceChart = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const percentageThreshold = 5; // Порог для объединения в категорию "Others"

  // Создаем массив меток и значений для диаграммы
  const labels = Object.keys(data);
  const balances = Object.values(data).map(exchange => exchange.coinInteger + exchange.usdtInteger + exchange.lockedInteger);

  // Создаем массив объектов, содержащих лейбл и соответствующий баланс
  const labeledBalances = labels.map((label, index) => {
    return { label, balance: balances[index] };
  });

  labeledBalances.sort((a, b) => b.balance - a.balance);

  // Извлекаем отсортированные метки и балансы
  const sortedLabels = labeledBalances.map(item => item.label);
  const sortedBalances = labeledBalances.map(item => item.balance);

  // Вычисляем общую сумму балансов для всех бирж
  const totalBalance = sortedBalances.reduce((acc, balance) => acc + balance, 0);

  // Вычисляем процентное соотношение каждого баланса
  const percentages = sortedBalances.map(balance => (balance / totalBalance) * 100);

  // Объединение данных в один массив для сортировки и фильтрации
  const combinedData = sortedLabels.map((label, index) => ({
    label,
    value: sortedBalances[index],
    percentage: percentages[index]
  }));

  // Сортировка данных по убыванию значения процента
  const sortedData = combinedData.sort((a, b) => b.percentage - a.percentage);

  // Разделение данных на основные и "Others"
  const topData = sortedData.filter(item => item.percentage >= percentageThreshold);
  const otherData = sortedData.filter(item => item.percentage < percentageThreshold);

  // Добавление категории "Others"
  if (otherData.length > 0) {
    topData.push({
      label: 'Others',
      value: otherData.reduce((acc, item) => acc + item.value, 0),
      percentage: otherData.reduce((acc, item) => acc + item.percentage, 0)
    });
  }

  const chartData = {
    labels: topData.map(item => item.label),
    datasets: [
      {
        data: topData.map(item => item.percentage),
        backgroundColor: [
          '#4CAF50', '#FF9800', '#F44336', '#2196F3', '#9C27B0',
          '#FFEB3B', '#009688', '#795548', '#607D8B', '#E91E63',
          '#3F51B5', '#CDDC39'
        ],
        hoverBackgroundColor: [
          '#66BB6A', '#FFB74D', '#EF5350', '#42A5F5', '#AB47BC',
          '#FFEE58', '#26A69A', '#8D6E63', '#78909C', '#EC407A',
          '#5C6BC0', '#D4E157'
        ]
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: function (title) {
            const label = title[0].label || '';
            const percentage = title[0].raw !== null ? title[0].raw.toFixed(2) + '%' : '';
            return `${label}: ${percentage}`;
          },
          label: function (context) {
            return null;
          }
        }
      },
    },
    cutout: '85%',
    elements: {
      arc: {
        borderWidth: 0,
        weight: 0.8,
        circumference: Math.PI / 5
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.chartContainer} style={{ width: '50%', height: '50%' }}>
        <div className={styles.chartTitle}>Balance Distribution</div>
        <Doughnut data={chartData} options={chartOptions} />
      </div>
      <div className={styles.legendContainer}>
        {topData.map((item, index) => (
          <div
            className={styles.legendItem}
            key={item.label}
            onClick={() => item.label === 'Others' && setShowModal(true)}
          >
            <div
              className={styles.legendColor}
              style={{ backgroundColor: chartData.datasets[0].backgroundColor[index] }}
            ></div>
            <div className={styles.legendText}>
              {item.label === 'Others' ? (
                <span className={styles.others}>
                  Others: {item.percentage.toFixed(2)}% <span className={styles.viewAll}>(View all)</span>
                </span>
              ) : (
                `${item.label}: ${item.percentage.toFixed(2)}%`
              )}
            </div>
          </div>
        ))}
      </div>
      {showModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <span className={styles.closeButton} onClick={() => setShowModal(false)}>
              &times;
            </span>
            <h2>Other Assets</h2>
            <ul>
              {otherData.map((item) => (
                <li key={item.label}>
                  {`${item.label}: ${item.percentage.toFixed(2)}%`}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default BalanceChart;
