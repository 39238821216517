import React from 'react';
import Percent from "../../TableComponents/Percent/Percent";
import ExchangeBuy from "../../TableComponents/ExchangeBuy/ExchangeBuy";
import Chain from "../../TableComponents/Chain/Chain";
import Commission from "../../TableComponents/Commission/Commission";
import ExchangeSell from "../../TableComponents/ExchangeSell/ExchangeSell";
import BuyPrice from "../../TableComponents/BuyPrice/BuyPrice";
import Income from "../../TableComponents/Income/Income";
import TotalBuyPrice from "../../TableComponents/TotalBuyPrice/TotalBuyPrice";
import TotalIncome from "../../TableComponents/TotalIncome/TotalIncome";
import Created from "../../TableComponents/Created/Created";
import Updated from "../../TableComponents/Updated/UpdatedRow";
import Coin from "../../TableComponents/Coin/Coin";
import {generateId} from "../../common/helpers/helpers";
import Balance from "../../TableComponents/Balance/Balance";

interface SpreadData {
    spread: any;
}

const Spread: React.FC<SpreadData> = ({spread}) => {
    const itsDex = window.location.pathname.includes('dex');
    const componentsForCex = [
        Coin,
        Percent,
        ExchangeBuy,
        Balance,
        Chain,
        Commission,
        ExchangeSell,
        BuyPrice,
        Income,
        TotalBuyPrice,
        TotalIncome,
        Created,
        Updated,
    ];

    const componentsForDex = [
        Coin,
        Percent,
        ExchangeBuy,
        Balance,
        Chain,
        Commission,
        ExchangeSell,
        TotalBuyPrice,
        TotalIncome,
        Created,
        Updated,
    ]

    const components = itsDex ? componentsForDex : componentsForCex;

    return (
        <tr key={generateId()}>
            {components.map(Component => (
                <Component spread={spread} key={generateId()}/>
            ))}
        </tr>
    );
};

export default Spread;