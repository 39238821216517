import {useNavigate} from "react-router-dom";
import {Button, Input} from 'antd';
import style from './Login.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {loginAsync, selectAuthStatus} from "../../redux/auth/authSlice";
import {useState} from "react";
import {siteLogo} from "../../common/helpers/imagesImport";
import {NotificationManager} from "react-notifications";
import Loader from "../../common/loader/Loader";
import {isValidEmail} from "../../common/helpers/auth";
import {selectTheme} from "../../redux/theme/themeSlice";

const Login = () => {
  const dispatch = useDispatch();
  const authStatus = useSelector(selectAuthStatus);
  const navigate = useNavigate();
  const theme = useSelector(selectTheme);
  const [email, setEmail] = useState('');
  const [password, setPass] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);

  const onFinish = async () => {
    if (!isValidEmail(email)) {
      setIsEmailValid(false);
      NotificationManager.error('Invalid Email');
      return
    }

    try {
      await dispatch(loginAsync({email, password}));
      navigate("/spreads");
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  const routeToRegister = () => {
    navigate('/auth/register')
  }

  const handleEmailChange = (value) => {
    setEmail(value);
    setIsEmailValid(true);
  };

  if (authStatus === 'loading') return <Loader />

  return (
    <div className={style.wrapper}>
      <div className={style.logo}>
        <img src={siteLogo} alt=""/>
        <h1>CRYPTO SPACE</h1>
      </div>

      <div className={`${style.auth} ${theme}`}>
        <span className={style.title}>LOG IN</span>

        <div className={style.inputs}>
          <Input
            className={style.theme}
            status={isEmailValid ? '' : 'error'}
            onChange={(e) => handleEmailChange(e.target.value)}
            value={email}
            size={'large'}
            placeholder="Email">
          </Input>
          <Input.Password
            onChange={(e) => setPass(e.target.value)}
            value={password}
            size={'large'}
            placeholder="Passowrd">
          </Input.Password>
        </div>

        <div className={style.buttons}>
          <Button onClick={onFinish} ghost size={'large'}>LOG IN</Button>

          <div className={style.additional}>
            <div className={style.signUp}>
                <span>No account? </span>
                <span onClick={routeToRegister}>Sign Up</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;