import style from '../../components/PortfolioWrapper/Portfolio.module.scss';

export function getBalanceClass(balanceStr: any) {
    const balance = parseFloat(balanceStr);
    return balance < 0 ? style.allProfitPercentNegative : style.allProfitPercentPositive;
}

export function getBalancePercentClass(balancePercent: any): any {
    const balance: any = parseFloat(balancePercent);
    if (balance < 0 && balance !== 0) {
        return style.negativePercent;
    } else if (balance > 0) {
        return style.positivePercent;
    } else if (balance === 0) {
        return style.zeroPercent;
    } else {
        return style.nanPercent;
    }
}

export function substringNegativePercent(percent: any): any {
    const balance: any = parseFloat(percent);
    if (balance < 0 && balance !== 0) {
        return balance.toLocaleString().substring(1);
    } else if (balance > 0) {
        return balance;
    } else if (balance === 0) {
        return '0';
    } else {
        return '-'
    }
}