import React from 'react';
import Header from "../Header/Header";
import ApiStatus from "./ApiStatus";

const ApiStatusWrapper = () => {
    return (
        <>
            <Header/>
            <ApiStatus />
        </>
    );
};

export default ApiStatusWrapper;