import React, {useContext, useEffect, useState} from 'react';
import Select from 'react-select';
import axios from 'axios';
import { getToken } from '../../auth/auth';
import { BASE_URL } from '../../api/api';
import '../Filters.scss';
import {GlobalContext} from "../../../components/routeWrapper/routeWrapper";

const ExcludeMarket = () => {
    const config = {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    };
    const [marketOptions, setMarketOptions] = useState([]);
    const [defaultValue, setDefaultValue] = useState([]);
    const { setMarketTriggerValue } = useContext(GlobalContext);

    const fetchData = async () => {
        try {
            const [exchangeResponse, defaultValueResponse] = await Promise.all([
                axios.get(`${BASE_URL}/data/exchange/autocomplete`, config),
                axios.get(`${BASE_URL}/filter?filterType=exclude&filterObjectType=exchanges`, config)
            ]);

            const exchangeData = exchangeResponse.data.map((exchange: any) => ({
                value: exchange,
                label: exchange.charAt(0).toUpperCase() + exchange.slice(1),
                color: '#696969'
            }));

            setMarketOptions(exchangeData);

            if (defaultValueResponse.data.values.length > 0 && defaultValueResponse.data.values[0] !== '') {
                const defaultValues = defaultValueResponse.data.values.map((value: any) => ({
                    value,
                    label: value.charAt(0).toUpperCase() + value.slice(1),
                    color: '#696969'
                }));
                setDefaultValue(defaultValues);
                setMarketTriggerValue(defaultValues);
            }
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
        }
    };

    const handleMenuOpen = async () => {
        try {
            const [exchangeResponse] = await Promise.all([
                axios.get(`${BASE_URL}/data/exchange/autocomplete`, config)
            ]);

            const exchangeData = exchangeResponse.data.map((exchange: any) => ({
                value: exchange,
                label: exchange.charAt(0).toUpperCase() + exchange.slice(1),
                color: '#696969'
            }));
            setMarketOptions(exchangeData);
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleValueChange = (selectedValues: any, action: any) => {
        if (action.action === 'select-option') {
            const lastSelectedValue = selectedValues[selectedValues.length - 1].value;
            axios.put(`${BASE_URL}/filter`, {
                filterType: 'exclude',
                filterObjectType: 'exchanges',
                value: lastSelectedValue
            }, config)
                .then(response => {
                    setMarketTriggerValue(response.data);
                })
        } else if (action.action === 'remove-value') {
            axios.delete(`${BASE_URL}/filter`, {
                data: {
                    filterType: 'exclude',
                    filterObjectType: 'exchanges',
                    value: action.removedValue.value
                }, headers: {
                    'Content-Type': 'application/json',
                    ...config.headers
                }
            })
                .then(response => {
                    setMarketTriggerValue(response.data);
                })
        } else if (action.action === 'clear') {
            axios.delete(`${BASE_URL}/filter/all`, {
                data: {
                    filterType: 'exclude',
                    filterObjectType: 'exchanges',
                }, headers: {
                    'Content-Type': 'application/json',
                    ...config.headers
                }
            })
                .then(response => {
                    setMarketTriggerValue(response.data);
                })
        }
    }

    const customStyles = {
        multiValue: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.data.color,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            color: provided.color,
        }),
        multiValueRemove: (provided: any) => ({
            ...provided,
            ':hover': {
                backgroundColor: '#FF0000', // Цвет при наведении на крестик
                color: 'white', // Цвет текста при наведении на крестик
            },
        }),
    };

    return (
        <div>
            <Select
                key={defaultValue ? JSON.stringify(defaultValue) : 'empty'}
                defaultValue={defaultValue}
                onMenuOpen={handleMenuOpen}
                onChange={handleValueChange}
                placeholder='Exclude Market'
                closeMenuOnSelect={false}
                isMulti
                options={marketOptions}
                styles={customStyles}
                // menuIsOpen={true}
            />
        </div>
    );
};

export default ExcludeMarket;