import React, {useContext, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectBudgetByBalanceValue, selectBudgetValue} from "../../redux/budget/budgetSlice";
import {selectSpreads} from "../../redux/spreads/spreadsSlice";
import {fetchDataAsync} from "../../redux/spreads/spreadsSlice";
import Spread from "./Spread";
import Head from "../../TableComponents/Head/Head";
import {generateId, getSpreadsLength} from "../../common/helpers/helpers";
import style from './SpreadTable.module.scss';
import {selectTheme} from "../../redux/theme/themeSlice";
import '../../themes.scss';
import {ThunkDispatch} from "redux-thunk";
import {RootState} from "@reduxjs/toolkit/query";
import {AnyAction} from "@reduxjs/toolkit";
import {FilteredSpread} from "../../common/interfaces/filteredSpread/filteredSpread";
import {SpreadResponse} from "../../common/interfaces/Spread/spread";
import {GlobalContext} from "../routeWrapper/routeWrapper";

enum interval {
    fetching = 10000
}

const FETCHING_INTERVAL: interval = interval.fetching;

const SpreadTable: React.FC = () => {
    const dispatch: ThunkDispatch<RootState<any, any, any>, any, AnyAction> = useDispatch();
    const budgetValue = useSelector(selectBudgetValue);
    const budgetByBalanceValue: any = useSelector(selectBudgetByBalanceValue);
    const spreads = useSelector(selectSpreads);
    const theme = useSelector(selectTheme);
    const useTheme = theme === 'light' ? 'lightTable' : 'darkTable';
    const budgetValueRef = useRef(budgetValue);
    const {chainTriggerValue, coinTriggerValue, marketTriggerValue} = useContext(GlobalContext);

    const fetchData = () => {
        dispatch(fetchDataAsync(budgetValueRef.current));
    }

    useEffect(() => {
        fetchData();

        const intervalId = setInterval(() => {
            fetchData();
        }, FETCHING_INTERVAL);

        return () => {
            clearInterval(intervalId);
        }
    }, [dispatch, chainTriggerValue, coinTriggerValue, marketTriggerValue, budgetByBalanceValue]);

    useEffect(() => {
        budgetValueRef.current = budgetValue;
    }, [budgetValue]);

    if (getSpreadsLength(spreads) === 0) {
        return <div className={style.notFoundSpread}>No spreads found for your budget. Try increasing it</div>
    }

    console.log('new build create test charts blocks');

    return (
        <div className={`${style.tableContainer} ${useTheme}`}>
            <table className={style.responsiveTable}>
                <Head/>
                <tbody>
                {spreads && spreads.map((spread: FilteredSpread) => (
                    <Spread spread={spread} key={generateId()}/>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default SpreadTable;