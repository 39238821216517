import React from 'react';
import style from "./Portfolio.module.scss";
import {formatedNumber} from "../../common/helpers/helpers";
import {getBalancePercentClass, substringNegativePercent} from "../../common/helpers/getBalanceClass";

const AllTimeWrapper = ({portfolio}: any) => {
    return (
        <div>
            <div className={style.allTimeWrapper}>
                <div className={style.allTimeItem}>
                    <div>
                        <div className={style.allTimeTittle}>Total balance</div>
                        <div
                            className={style.allTimeProfitCount}>${formatedNumber(portfolio.totalBalanceInUsdt)}</div>
                        <div
                            className={getBalancePercentClass(portfolio.totalBalanceInUsdtDifferenceInPercent)}>{substringNegativePercent(portfolio.totalBalanceInUsdtDifferenceInPercent)}%
                        </div>
                    </div>
                </div>
                <div className={style.allTimeItem}>
                    <div className={style.allTimeTittle}>Best performer</div>
                    <div className={style.allTimeName}>
                        <img width='24px' src={portfolio?.bestPerformer?.imageUrl}
                             className={style.allTimeImg}/>
                        <span className={style.allTimeNameDesc}>{portfolio?.bestPerformer?.coin}</span>
                    </div>
                    <div className={style.allTimePercentBest}>
                            <span
                                className={style.allTimePercentBestBalance}>${formatedNumber(portfolio?.bestPerformer?.allTimeDifferenceInUsdt)}</span>
                        <span
                            className={style.allTimePercentBestValue}>{portfolio?.bestPerformer?.allTimeDifferenceInPercent}%</span>
                    </div>
                </div>
                <div className={style.allTimeItem}>
                    <div className={style.allTimeTittle}>Worst performer</div>
                    <div className={style.allTimeName}>
                        <img src={portfolio?.worstPerformer?.imageUrl}
                             className={style.allTimeImg}/>
                        <span className={style.allTimeNameDesc}>{portfolio?.worstPerformer?.coin}</span>
                    </div>
                    <div className={style.allTimePercentWorst}>
                            <span
                                className={style.allTimePercentWorstBalance}>- ${formatedNumber(portfolio?.worstPerformer?.allTimeDifferenceInUsdt)}</span>
                        <span
                            className={style.allTimePercentWorstValue}>{portfolio?.worstPerformer?.allTimeDifferenceInPercent}%</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllTimeWrapper;