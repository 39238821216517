import React from 'react';
import {generateId} from "../../common/helpers/helpers";
import style from "../../components/SpreadTable/SpreadTable.module.scss";
import {commissionHint, marketLogos} from "../../common/helpers/imagesImport";
import {getColorsMarket, Market} from "../../common/helpers/getColorsMarket";
import Tooltip from "../../common/tooltip/Tooltip";

interface ExchangeSellProps {
    spread: {
        exchangeSell: {
            exchangeSell: Market;
            exchangeSellTradeLink: string;
            exchangeSellDepositLink: string;
            depositTips: string;
        }[]
    }
}

const ExchangeSell: React.FC<ExchangeSellProps> = ({spread}) => {
    return (
        <td>{spread.exchangeSell.map((exchangeSell) => (
            <div key={generateId()} className={`${style.sellMarket} ${style.lastSpread}`}>
                <div className={style.sellLogo}>
                    <a href={exchangeSell.exchangeSellTradeLink} target='_blank'>
                        <img src={marketLogos[exchangeSell.exchangeSell]} alt={exchangeSell.exchangeSell}/>
                    </a>
                </div>
                <div className={style.sellName}
                     style={{backgroundColor: getColorsMarket(exchangeSell.exchangeSell)}}>
                    <a href={exchangeSell.exchangeSellTradeLink} target='_blank'>{exchangeSell.exchangeSell}</a>
                </div>
                <div className={style.dep}>
                    <a href={exchangeSell.exchangeSellDepositLink} target='_blank'>dep</a>
                    {exchangeSell.depositTips &&
                      <div className={style.withDrawContainer}>
                        <Tooltip content={exchangeSell.depositTips} direction="top">
                          <img className={style.withdrawTips} src={commissionHint} alt='Check deposit commission'/>
                        </Tooltip>
                      </div>
                    }
                </div>
            </div>
        ))}</td>
    );
};

export default ExchangeSell;