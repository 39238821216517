import React, {useEffect, useRef, useState} from 'react';
import style from '../../components/SpreadTable/SpreadTable.module.scss';
import axios from "axios";
import {BASE_URL} from "../../common/api/api";
import {useDispatch, useSelector} from "react-redux";
import {selectBudgetValue} from "../../redux/budget/budgetSlice";
import {fetchDataAsync} from "../../redux/spreads/spreadsSlice";
import {ThunkDispatch} from "redux-thunk";
import {RootState} from "@reduxjs/toolkit/query";
import {AnyAction} from "@reduxjs/toolkit";
import {getAuth} from "../../common/helpers/auth";

interface CoinProps {
    spread: {
        coin: string;
        pinned: boolean;
    }
}

const Coin: React.FC<CoinProps> = ({spread}): React.ReactElement => {
    const dispatch: ThunkDispatch<RootState<any, any, any>, any, AnyAction> = useDispatch();
    const budgetValue = useSelector(selectBudgetValue);
    const budgetValueRef = useRef(budgetValue);
    const [isPin, setIsPin] = useState(spread.pinned);

    const coinHandler = async () => {
        setIsPin((prev) => !prev);

        const data = {
            filterType: 'pin',
            filterObjectType: 'coins',
            value: spread.coin
        };

        const headers = getAuth().headers;

        try {
            isPin
                ? await axios.delete(`${BASE_URL}/filter`, {data, headers})
                : await axios.put(`${BASE_URL}/filter`, data, {headers});
            dispatch(fetchDataAsync(budgetValueRef.current));
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        budgetValueRef.current = budgetValue;
    }, [budgetValue]);

    return (
        <td>
            <div className={style.coinWrapper}>
                 <span className={isPin ? style.pinned : ''} onClick={coinHandler}>
                     <svg
                         xmlns="http://www.w3.org/2000/svg"
                         xmlnsXlink="http://www.w3.org/1999/xlink"
                         viewBox="0 0 32 32"
                         version="1.1"
                     >
                        <g id="surface1">
                            <path
                                style={{}}
                                d="M 20.53125 2.5625 L 19.84375 3.5 L 14.9375 10.1875 C 12.308594 9.730469 9.527344 10.472656 7.5 12.5 L 6.78125 13.1875 L 12.09375 18.5 L 4 26.59375 L 4 28 L 5.40625 28 L 13.5 19.90625 L 18.8125 25.21875 L 19.5 24.5 C 21.527344 22.472656 22.269531 19.691406 21.8125 17.0625 L 28.5 12.15625 L 29.4375 11.46875 Z M 20.78125 5.625 L 26.375 11.21875 L 20.15625 15.78125 L 19.59375 16.1875 L 19.78125 16.84375 C 20.261719 18.675781 19.738281 20.585938 18.59375 22.1875 L 9.8125 13.40625 C 11.414063 12.261719 13.324219 11.738281 15.15625 12.21875 L 15.8125 12.40625 L 16.21875 11.84375 Z "
                            />
                        </g>
                    </svg>
                </span>
                {spread.coin}
            </div>
        </td>
    );
};

export default Coin;