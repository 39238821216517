import React, {useEffect, useState} from 'react';
import style from './ApiStatus.module.scss';
import {getToken} from "../../common/auth/auth";
import axios from "../../common/auth/axiosInterceptor";
import {BASE_URL} from "../../common/api/api";
import Modal from "../../common/modal/Modal";
import {getAuth} from "../../common/helpers/auth";

const ApiStatus = () => {
    const headers = ['Market', 'Status', 'Last update', 'Action'];
    const [apiAssets, setApiAssets] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState(null);

    const deleteApiKey = async (asset:any) => {
        const headers = getAuth().headers;

        try {
            await axios.delete(`${BASE_URL}/apiKey`, {
                data: {exchange: asset.exchange},
                headers,
            });
            console.log('API key removed:', asset.exchange);
            fetchData();
        } catch (error) {
            console.error('Error removing API key:', error);
        }
    };

    const openModal = (asset: any) => {
        setSelectedAsset(asset);
        setShowModal(true);
    };

    const closeModal = (event: any) => {
        if (event) event.preventDefault();
        setShowModal(false);
        setSelectedAsset(null);
    };

    const formatDate = (dateString: any) => {
        if (dateString) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}/${month}/${day}`;
        } else {
            return '-'
        }
    };

    const fetchData = async () => {
        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            };
            const response = await axios.get(`${BASE_URL}/apiKey`, config);
            setApiAssets(response.data);
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <div className={style.wrapper}>
            <table>
                <thead>
                <tr>
                    {headers.map((header: any) => (
                        <th key={header}>{header}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {apiAssets.map((asset: any) => (
                    <tr key={asset.exchange}>
                        <td>{asset.exchange}</td>
                        <td>
                            <span className={style.statusWrapper}>
                              <span
                                  className={`${style.tick} ${asset.status === 'active' ? style['tick-success'] : style['tick-error']}`}></span>
                            <span>{asset.status === 'active' ? 'Connected' : 'Not сonnected'}</span>
                            </span>
                        </td>
                        <td>{formatDate(asset.updated)}</td>
                        <td>
                            <button
                                className={style.btn}
                                onClick={() => {
                                    if (asset.status === 'active') {
                                        deleteApiKey(asset);
                                    } else {
                                        openModal(asset);
                                    }
                                }}>
                                {asset.status === 'active' ? 'Remove API Key' : 'Add API Key'}
                            </button>
                            <Modal show={showModal} onClose={closeModal} asset={selectedAsset} fetchData={fetchData}/>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default ApiStatus;