export function getAuth() {
    const authToken = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    };
    return config;
}

export function isValidEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}
