import React from 'react';
import {generateId} from "../../common/helpers/helpers";
import style from "../../components/SpreadTable/SpreadTable.module.scss";

interface TotalBuyProps {
    spread: {
        totalBuyPrice: string[];
        price: string[]
    }
}

const TotalBuyPrice: React.FC<TotalBuyProps> = ({spread}) => {
    return (
        <td>{spread.totalBuyPrice.map((price) => (
            <div key={generateId()} className={style.lastSpread}>
                <div>{price}</div>
            </div>
        ))}</td>
    );
};

export default TotalBuyPrice;