import React, {useState} from 'react';
import Header from "../Header/Header";
import FilterPanel from "../FilterPanel/FilterPanel";
import SpreadTable from "../SpreadTable/SpreadTable";

interface ContextValue {
    chainTriggerValue: any[];
    coinTriggerValue: any[];
    marketTriggerValue: any[];
    setChainTriggerValue: React.Dispatch<any>;
    setCoinTriggerValue: React.Dispatch<any>;
    setMarketTriggerValue: React.Dispatch<any>;
}

const initialValue: ContextValue = {
    chainTriggerValue: [],
    coinTriggerValue: [],
    marketTriggerValue: [],
    setChainTriggerValue: () => {
    },
    setCoinTriggerValue: () => {
    },
    setMarketTriggerValue: () => {
    }
};

export const GlobalContext = React.createContext(initialValue as ContextValue);

const RouteWrapper: React.FC = (): React.ReactElement => {
    const [chainTriggerValue, setChainTriggerValue] = useState<any[]>([]);
    const [coinTriggerValue, setCoinTriggerValue] = useState<any[]>([]);
    const [marketTriggerValue, setMarketTriggerValue] = useState<any[]>([]);

    return (
        <GlobalContext.Provider value={{
            chainTriggerValue,
            coinTriggerValue,
            marketTriggerValue,
            setChainTriggerValue,
            setCoinTriggerValue,
            setMarketTriggerValue
        }}>
            <Header/>
            <FilterPanel/>
            <SpreadTable/>
        </GlobalContext.Provider>
    );
};

export default RouteWrapper;