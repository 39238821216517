import React, {useContext, useEffect, useRef, useState} from 'react';
import Select from "react-select";
import axios from "axios";
import {BASE_URL} from "../../api/api";
import {getToken} from "../../auth/auth";
import {GlobalContext} from "../../../components/routeWrapper/routeWrapper";

const ExcludeChain = () => {
    const config = {
        headers: {
            'Authorization': `Bearer ${getToken()}`
        }
    };

    const [chainsOptions, setChainsOptions] = useState([]);
    const [defaultValue, setDefaultValue] = useState([]);
    const {setChainTriggerValue} = useContext(GlobalContext);

    const customStyles = {
        multiValue: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: "#696969",
        }),
        multiValueRemove: (provided: any) => ({
            ...provided,
            ':hover': {
                backgroundColor: '#FF0000', // Цвет при наведении на крестик
                color: 'white', // Цвет текста при наведении на крестик
            },
        }),
    };

    const handleValueChange = (selectedValues: any, action: any) => {
        if (action.action === 'select-option') {
            const lastSelectedValue = selectedValues[selectedValues.length - 1].value;
            axios.put(`${BASE_URL}/filter`, {
                filterType: 'exclude',
                filterObjectType: 'chains',
                value: lastSelectedValue
            }, config)
                .then(response => {
                    setChainTriggerValue(response.data);
                })
        } else if (action.action === 'remove-value') {
            axios.delete(`${BASE_URL}/filter`, {
                data: {
                    filterType: 'exclude',
                    filterObjectType: 'chains',
                    value: action.removedValue.value
                }, headers: {
                    'Content-Type': 'application/json',
                    ...config.headers
                }
            })
                .then(response => {
                    setChainTriggerValue(response.data);
                })
        } else if (action.action === 'clear') {
            axios.delete(`${BASE_URL}/filter/all`, {
                data: {
                    filterType: 'exclude',
                    filterObjectType: 'chains',
                }, headers: {
                    'Content-Type': 'application/json',
                    ...config.headers
                }
            })
                .then(response => {
                    setChainTriggerValue(response.data);
                })
        }
    }

    const fetchData = async () => {
        try {
            const [chainResponse, defaultValueResponse] = await Promise.all([
                axios.get(`${BASE_URL}/data/chain/autocomplete`, config),
                axios.get(`${BASE_URL}/filter?filterType=exclude&filterObjectType=chains`, config)
            ]);

            const chainData = chainResponse.data.map((exchange: any) => ({
                value: exchange,
                label: exchange.charAt(0).toUpperCase() + exchange.slice(1),
                color: '#696969'
            }));

            setChainsOptions(chainData);

            if (defaultValueResponse.data.values.length > 0 && defaultValueResponse.data.values[0] !== '') {
                const defaultValues = defaultValueResponse.data.values.map((value: any) => ({
                    value,
                    label: value.charAt(0).toUpperCase() + value.slice(1),
                    color: '#696969'
                }));
                setDefaultValue(defaultValues);
                setChainTriggerValue(defaultValues);
            }
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
        }
    };

    const handleMenuOpen = async () => {
        try {
            const [chainResponse] = await Promise.all([
                axios.get(`${BASE_URL}/data/chain/autocomplete`, config),
            ]);

            const chainData = chainResponse.data.map((exchange: any) => ({
                value: exchange,
                label: exchange.charAt(0).toUpperCase() + exchange.slice(1),
                color: '#696969'
            }));

            setChainsOptions(chainData);
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <>
            <Select
                key={defaultValue ? JSON.stringify(defaultValue) : 'empty'}
                defaultValue={defaultValue}
                onMenuOpen={handleMenuOpen}
                onChange={handleValueChange}
                placeholder='Exclude Chain'
                closeMenuOnSelect={false}
                isMulti
                options={chainsOptions}
                styles={customStyles}
                // menuIsOpen={true}
            />
        </>
    );
};

export default ExcludeChain;