import React, {useState, useEffect} from 'react';
import {
  LineChart,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  ReferenceLine
} from 'recharts';
import styled, {createGlobalStyle} from 'styled-components';
import {getToken} from "../../common/auth/auth";
import axios from "axios";
import {BASE_URL} from "../../common/api/api";
import moment from 'moment';


const GlobalStyle = createGlobalStyle`
  body {
    background-color: #121212;
    color: #fff;
    font-family: 'Arial', sans-serif;
    cursor: pointer;
  }
`;

const GraphContainer = styled.div`
  padding: 24px 24px 40px 24px;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  max-width: 630px;
  max-height: 297px;
`;

const Title = styled.h3`
  color: #fff;
  margin-bottom: 10px;
  text-align: left;
  font-size: 18px;
`;

const CustomTooltip = ({active, payload, label}) => {
  if (active && payload && payload.length) {
    const value = payload[0].value.toFixed(2); // Округление значения до 2 знаков после запятой
    const date = moment(label).format('ddd. MMM D, YYYY HH:mm UTCZ'); // Форматирование даты
    return (
      <div style={{backgroundColor: '#2a2a2a', padding: '10px', borderRadius: '5px', color: '#fff'}}>
        <p>{date}</p>
        <p>{`Total Value: $${value}`}</p>
      </div>
    );
  }

  return null;
};


const FETCHING_INTERVAL = 10000;

const USDTGraph = () => {
  const [graphData, setGraphData] = useState([]);
  const [tooltipPosition, setTooltipPosition] = useState({x: 0, y: 0});
  const [isTooltipActive, setIsTooltipActive] = useState(false);

  const fetchData = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${getToken()}`
        }
      };
      const response = await axios.get(`${BASE_URL}/portfolio/historicalChart`, config);
      setGraphData(response.data);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, FETCHING_INTERVAL);

    return () => {
      clearInterval(intervalId);
    }
  }, []);

  const getLineColor = () => {
    if (graphData.length === 0) return '#00FF00'; // Default color if no data
    const latestValue = graphData[graphData.length - 1]?.value;
    const initialValue = graphData[0]?.value;
    return latestValue >= initialValue ? '#00FF00' : '#FF4500'; // Изменение цвета
  };

  const handleMouseMove = (e) => {
    if (e && e.activeTooltipIndex) {
      setTooltipPosition({ x: e.chartX, y: e.chartY });
      setIsTooltipActive(true);
    } else {
      setIsTooltipActive(false);
    }
  };

  return (
    <div>
      <GlobalStyle/>
      <GraphContainer className={isTooltipActive ? 'pointer-cursor' : ''}>
        <Title>History</Title>
        <ResponsiveContainer width="100%" height="100%" style={{cursor: 'pointer'}}>
          <ComposedChart data={graphData} onMouseMove={handleMouseMove}>
            <CartesianGrid strokeDasharray="0" stroke="#444" vertical={false}/>
              <XAxis
                dataKey="date"
                stroke="#888"
                tickFormatter={(tick) => moment(tick).format('h:mm A')}
                tick={{ fill: '#888', fontSize: 12 }}
                tickSize={0}
                dy={6}
                dx={-30}
              />
            <YAxis
              domain={['dataMin', 'dataMax']}
              stroke="#888"
              tickFormatter={(tick) => `$${tick.toFixed(2)}`}
              tick={{fill: '#888', fontSize: 12}}
              tickSize={0}
              orientation="right"
              axisLine={false}
              dx={10}
            />
            <Tooltip
              content={<CustomTooltip/>}
              position={tooltipPosition}
              cursor={{stroke: '#888', strokeWidth: 1}}
              wrapperStyle={{zIndex: 1000, outline: 'none'}}
            />
            <ReferenceLine y="dataMin" stroke="transparent"/>
            <ReferenceLine y="dataMax" stroke="transparent"/>
            <Area
              cursor='pointer'
              type="monotone"
              dataKey="value"
              stroke="transparent"
              fill="#334f52"
              fillOpacity={0.3}
              activeDot={false}
              dot={false}
              isAnimationActive={false}
            />
            <Line
              cursor='pointer'
              type="monotone"
              dataKey="value"
              stroke="#18D990"
              activeDot={false}
              dot={false} // Удаление маркера
              strokeWidth={2}
              isAnimationActive={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </GraphContainer>
    </div>
  );
};

export default USDTGraph;
