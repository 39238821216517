import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";

interface BudgetState {
    budgetChange: boolean;
    value: string;
    budgetByBalance: any;
}

const budgetValueStorage = localStorage.getItem('BUDGET_VALUE');
const budgetByBalanceStorage = localStorage.getItem('BUDGET_BY_BALANCE');
const parsedBudgetByBalance = budgetByBalanceStorage ? JSON.parse(budgetByBalanceStorage) : false;

const initialState: BudgetState = {
    budgetChange: false,
    value: budgetValueStorage ? budgetValueStorage : '100',
    budgetByBalance: parsedBudgetByBalance
}

export const budgetSlice = createSlice({
    name: 'budget',
    initialState,
    reducers: {
        setBudgetValue: (state: Draft<BudgetState>, action: PayloadAction<string>) => {
            state.value = action.payload;
        },
        setBudgetByBalanceValue: (state: Draft<BudgetState>, action: PayloadAction<any>) => {
            state.budgetByBalance = action.payload;
        },
        setBudgetChange: (state: Draft<BudgetState>, action: PayloadAction<boolean>) => {
            state.budgetChange = action.payload;
        }
    }
});

export const {setBudgetValue, setBudgetChange, setBudgetByBalanceValue} = budgetSlice.actions;

export const selectBudgetValue = (state: { budget: BudgetState }) => state.budget.value;
export const selectBudgetByBalanceValue: any = (state: { budget: BudgetState }) => state.budget.budgetByBalance;
export const selectBudgetChange = (state: { budget: BudgetState }) => state.budget.budgetChange;

export default budgetSlice.reducer;