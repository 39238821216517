import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../redux/auth/authSlice";
import style from './Header.module.scss';
import {selectTheme, setTheme} from "../../redux/theme/themeSlice";
import {NavLink, useNavigate} from "react-router-dom";
import {LogoutOutlined, MoonOutlined, SunOutlined, UserOutlined} from "@ant-design/icons";
import {Avatar, Dropdown, DropdownProps, MenuProps} from "antd";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "@reduxjs/toolkit";

const Header: React.FC = (): React.ReactElement => {
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const theme: string = useSelector(selectTheme);
    const changeTheme = () => {
        const themeValue = theme === 'light' ? 'dark' : 'light';
        dispatch(setTheme(themeValue));
        localStorage.setItem('theme', themeValue);
    }

    const logOut = () => {
        dispatch(logout());
        navigate('/auth/login')
    }

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        setOpen(false);
    };

    const handleOpenChange: DropdownProps['onOpenChange'] = (nextOpen, info) => {
        if (info.source === 'trigger' || nextOpen) {
            setOpen(nextOpen);
        }
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
              <NavLink to='/profile' className={style.dropdownItem}>
                  <UserOutlined className={style.logout}/>
                  <span>Profile</span>
              </NavLink>
            ),
        },
        {
            key: '2',
            label: (
              <div className={style.dropdownItem} onClick={() => logOut()}>
                  <LogoutOutlined className={style.logout}  />
                  <span>Logout</span>
              </div>
            ),
        },
    ];

    return (
        <header className={`${style.header} ${theme}`}>
            <div className={style.titleWrapper}>
                <NavLink className={({ isActive }) =>
                    isActive ? style.activeLink : style.link
                } to='/spreads'>Spreads</NavLink>
                <NavLink className={({ isActive }) =>
                    isActive ? style.activeLink : style.link
                } to='/dex'>Dex</NavLink>
                <NavLink className={({ isActive }) =>
                    isActive ? style.activeLink : style.link
                } to='/portfolio'>Wallet</NavLink>
                <NavLink className={({ isActive }) =>
                    isActive ? style.activeLink : style.link
                } to='/api-status'>API Status</NavLink>
                <NavLink className={({ isActive }) =>
                    isActive ? style.activeLink : style.link
                } to='/profile'>Profile</NavLink>
            </div>
            <div className={style.infoWrapper}>
                {/*<span>Users online: <strong>1</strong></span>*/}
                {/*<span>Spreads count: <strong>{spreadsCount}</strong></span>*/}
                <div className={style.themeIcon} onClick={changeTheme}>
                    {theme === 'light' ? <MoonOutlined /> : <SunOutlined />}
                </div>

                <Dropdown
                  menu={{items, onClick: handleMenuClick}}
                  onOpenChange={handleOpenChange}
                  open={open}
                >
                    <Avatar size={40} className={style.avatar}><UserOutlined/></Avatar>
                </Dropdown>
            </div>
        </header>
    );
};

export default Header;
