import axios from 'axios';
import { getToken } from './auth';
import {NotificationManager} from "react-notifications";

const axiosInstance = axios.create();

// Устанавливаем интерсепторы для запросов
axiosInstance.interceptors.request.use(
  config => {
    const token = getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Устанавливаем интерсепторы для ответов
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 403) {
      NotificationManager.error(error.response.data.message);
      window.location.href = '/subscription';
    }
    if (error.response.status === 401) {
      // Если получена ошибка 401 (несанкционированный доступ), перенаправляем на страницу входа
      window.location.href = '/auth/login';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
