import {configureStore, Dispatch} from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/query";
import spreadsReducer from './spreads/spreadsSlice';
import budgetReducer from './budget/budgetSlice';
import themeReducer from './theme/themeSlice';
import authReducer from './auth/authSlice';
import userReducer from './user/userSlice';

export type RootState = {
    spreads: ReturnType<typeof spreadsReducer>;
    budget: ReturnType<typeof budgetReducer>;
};

type ThunkDispatch = Dispatch<any>;

const store = configureStore({
    reducer: {
        spreads: spreadsReducer,
        budget: budgetReducer,
        theme: themeReducer,
        auth: authReducer,
        profile: userReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
    devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch as ThunkDispatch);

export default store;