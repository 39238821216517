import React, {useEffect, useState} from 'react';
import Header from "../Header/Header";
import {useDispatch, useSelector} from "react-redux";
import {changePassword, getProfile, selectGetProfile} from "../../redux/user/userSlice";

import style from './Profile.module.scss';
import {Button, Input} from "antd";
import {selectTheme} from "../../redux/theme/themeSlice";

const Profile = () => {
  const theme = useSelector(selectTheme);
  const dispatch = useDispatch();
  const profile = useSelector(selectGetProfile);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const resetPassword = async () => {
    const resp = await dispatch(changePassword({oldPassword, newPassword}));

    if (resp.payload === 'ok') {
      setOldPassword('');
      setNewPassword('');
    }
  }

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  return (
    <>
      <Header />

      <div className={`${style.Profile} ${theme}`}>
        <div className={style.items}>
          <div className={style.item}>
            <span>Email</span>
            <Input disabled value={profile && profile.email}/>
          </div>

          <div className={style.item}>
            <span>Expired date</span>
            <Input disabled value={profile && profile.subscriptionValidTill}/>
          </div>
        </div>

        <div className={style.items}>
          <div className={style.item}>
            <span>Change password</span>
            <Input.Password
              onChange={(e) => setOldPassword(e.target.value)}
              value={oldPassword}
              placeholder={'Old password'}
            />
            <Input.Password
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              placeholder={'New password'}
            />
            <Button onClick={resetPassword} ghost size={'large'}>Change</Button>
          </div>
        </div>

      </div>
    </>
  );
};

export default Profile;