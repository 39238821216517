import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";

interface ThemeState {
    theme: string;
}

const themeValue = localStorage.getItem('theme');

const initialState: ThemeState = {
    theme: themeValue ? themeValue : 'light'
}

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setTheme: (state: Draft<ThemeState>, action: PayloadAction<string>) => {
            state.theme = action.payload;
        },
    }
});

export const {setTheme} = themeSlice.actions;

export const selectTheme = (state: { theme: ThemeState }) => state.theme.theme;

export default themeSlice.reducer;