import lbankLogo from '../../assets/images/lbank.webp';
import mexcLogo from '../../assets/images/mexc.jpg';
import bitgetLogo from '../../assets/images/bitget.jpg';
import bitmartLogo from '../../assets/images/bitmart.ico';
import commissionHintLogo from '../../assets/images/tooltipCommission.svg';
import toolTipLogo from '../../assets/images/tooltipContract.svg';
import bitrueLogo from '../../assets/images/bitrue.ico';
import gateioLogo from '../../assets/images/gateio.png';
import probitLogo from '../../assets/images/probit.png';
import hitbtcLogo from '../../assets/images/hitbtc.ico';
import kucoinLogo from '../../assets/images/kucoin.png';
import binanceLogo from '../../assets/images/binance.ico';
import bybitLogo from '../../assets/images/bybit.png';
import bingxLogo from '../../assets/images/bingx.png';
import huobiLogo from '../../assets/images/huobi.ico';
import xtLogo from '../../assets/images/xt.png';
import coinexLogo from '../../assets/images/coinex.ico';
import openoceanLogo from '../../assets/images/openocean.png';
import links from '../../assets/images/links.svg';
import mainLogo from '../../assets/images/logo.png';
import ghostLogo from '../../assets/images/ghost.png';

type LogoType = {
    lbank: string;
    mexc: string;
    bitget: string;
    bitmart: string;
    bitrue: string;
    gateio: string;
    probit: string;
    hitbtc: string;
    kucoin: string;
    binance: string;
    bybit: string;
    bingx: string;
    huobi: string;
    xt: string;
    coinex: string;
    openocean: string;
}

export const lbank: string = lbankLogo;
export const mexc: string = mexcLogo;
export const bitget: string = bitgetLogo;
export const bitmart: string = bitmartLogo;
export const bitrue: string = bitrueLogo;
export const gateio = gateioLogo;
export const probit = probitLogo;
export const hitbtc = hitbtcLogo;
export const kucoin = kucoinLogo;
export const binance = binanceLogo;
export const bybit = bybitLogo;
export const bingx = bingxLogo;
export const huobi = huobiLogo;
export const xt = xtLogo;
export const coinex = coinexLogo;
export const openocean = openoceanLogo;
export const logo = mainLogo;

export const marketLogos: LogoType = {
    lbank,
    mexc,
    bitget,
    bitmart,
    bitrue,
    gateio,
    probit,
    hitbtc,
    kucoin,
    binance,
    bybit,
    bingx,
    huobi,
    xt,
    coinex,
    openocean
};

export const commissionHint: string = commissionHintLogo;
export const toolTipIcon: string = toolTipLogo;
export const openLinks: string = links;
export const siteLogo: string = mainLogo;
export const ghost: string = ghostLogo;