import React from 'react';
import {generateId} from "../../common/helpers/helpers";
import style from "../../components/SpreadTable/SpreadTable.module.scss";
import Tooltip from "../../common/tooltip/Tooltip";
import {toolTipIcon} from "../../common/helpers/imagesImport";

interface ChainProps {
    spread: {
        chain: {
            chain: string;
            sameContract: boolean | null;
            depositConfirm: string | null;
            chainAverageTime: string | null;
        }[];
    }
}


const Chain: React.FC<ChainProps> = ({spread}): React.ReactElement => {
    const setNetworkInfo = (chain: any) => {
        return (chain.chainAverageTime) &&
            `Average time: ${chain.chainAverageTime}`;
    }
    return (
        <td>{spread.chain.map((chain) => (
            <div key={generateId()} className={`${style.lastSpread} ${style.chainWrapper}`}>
                <div className={style.chain}>
                    <Tooltip content={setNetworkInfo(chain)} direction="top">
                        <span className={chain.chainAverageTime && style.chainName}>{chain.chain}</span>
                        {chain.depositConfirm && <span>&nbsp;({chain.depositConfirm})</span>}
                    </Tooltip>
                    {chain.sameContract &&
                      <div className={style.withDrawContainer}>
                        <Tooltip content='The contracts match' direction="top">
                          <img className={style.chainTips} src={toolTipIcon} alt='The contracts match'/>
                        </Tooltip>
                      </div>
                    }
                </div>
            </div>
        ))}</td>
    );
};

export default Chain;