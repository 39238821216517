import React from 'react';
import {generateId} from "../../common/helpers/helpers";
import style from "../../components/SpreadTable/SpreadTable.module.scss";

interface ChainProps {
    spread: any;
}


const Chain: React.FC<ChainProps> = ({spread}): React.ReactElement => {
    return (
        <td>{spread.balance.map((balance: any) => (
            <div key={generateId()} className={`${style.lastSpread}`}>
                <span>{balance !== null ? `~ ${balance}$` : '-'}</span>
            </div>
        ))}</td>
    );
};

export default Chain;