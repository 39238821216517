import React from 'react';
import {generateId} from "../../common/helpers/helpers";
import style from "../../components/SpreadTable/SpreadTable.module.scss";

interface CreatedProps {
    spread: {
        created: string[]
    }
}

const Created: React.FC<CreatedProps> = ({spread}): React.ReactElement => {
    return (
        <td>{spread.created.map((created) => (
            <div key={generateId()} className={style.lastSpread}>
                <div>{created}</div>
            </div>
        ))}</td>
    );
};

export default Created;