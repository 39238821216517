export enum Market {
    MEXC = 'mexc',
    BITMART = 'bitmart',
    BITGET = 'bitget',
    BITRUE = 'bitrue',
    LBANK = 'lbank',
    GATEIO = 'gateio',
    PROBIT = 'probit',
    HITBTC = 'hitbtc',
    KUCOIN = 'kucoin',
    BINANCE = 'binance',
    BYBIT = 'bybit',
    BINGX = 'bingx',
    HUOBI = 'huobi',
    XT = 'xt',
    COINEX = 'coinex',
    OPENOCEAN = 'openocean',
}


export const getColorsMarket = (value: Market): string => {
    switch (value) {
        case Market.MEXC:
            return '#008000';
        case Market.BITMART:
            return '#092727';
        case Market.BITGET:
            return '#1EA2B4';
        case Market.BITRUE:
            return '#8B5926';
        case Market.LBANK:
            return '#B59100';
        case Market.GATEIO:
            return '#053bb3';
        case Market.PROBIT:
            return '#4231C8';
        case Market.HITBTC:
            return '#056C95';
        case Market.KUCOIN:
            return '#01BC8D';
        case Market.BINANCE:
            return '#F3BA2E';
        case Market.BYBIT:
            return '#15192A';
        case Market.BINGX:
            return '#2A54FF';
        case Market.HUOBI:
            return '#008CD6';
        case Market.XT:
            return '#f6b848cc';
        case Market.COINEX:
            return '#38756b';
        case Market.OPENOCEAN:
            return '#93756b';
        default:
            throw new Error(`Invalid market value: ${value}`);
    }
}