import React from 'react';
import style from './Portfolio.module.scss';
import {determineStateBalance, formatedNumber, generateId} from "../../common/helpers/helpers";
import {getBalanceClass, getBalancePercentClass, substringNegativePercent} from "../../common/helpers/getBalanceClass";

const PortfolioTable = ({coins}: any) => {
    return (
        <table>
            <thead>
            <tr>
                <th>Name</th>
                <th>Price</th>
                <th>1h%</th>
                <th>24h%</th>
                <th>7d%</th>
                <th>90d%</th>
                <th>Holdings</th>
                <th>Profit/Loss</th>
            </tr>
            </thead>
            <tbody>
            {coins && coins.map((coin: any) => (
                <tr key={generateId()}>
                    <td>
                        <div className={style.name}>
                            <img
                                src={coin.imageUrl ? coin.imageUrl : 'https://s2.coinmarketcap.com/static/img/coins/64x64/10407.png'}
                                alt={coin.coin}/>
                            <span>{coin.coin}</span>
                        </div>
                    </td>
                    <td>
                        <span>${coin.price}</span>
                    </td>
                    <td>
                        <span
                            className={getBalancePercentClass(coin.hour1DifferenceInPercent)}>{coin.hour1DifferenceInPercent || coin.hour1DifferenceInPercent === 0 ? `${substringNegativePercent(coin.hour1DifferenceInPercent)}%` : '-'}</span>
                    </td>
                    <td>
                        <span
                            className={getBalancePercentClass(coin.hour24DifferenceInPercent)}>{coin.hour24DifferenceInPercent || coin.hour24DifferenceInPercent === 0 ? `${substringNegativePercent(coin.hour24DifferenceInPercent)}%` : '-'}</span>
                    </td>
                    <td>
                        <span
                            className={getBalancePercentClass(coin.day7DifferenceInPercent)}>{coin.day7DifferenceInPercent || coin.day7DifferenceInPercent === 0 ? `${substringNegativePercent(coin.day7DifferenceInPercent)}%` : '-'}</span>
                    </td>
                    <td>
                        <span
                            className={getBalancePercentClass(coin.day90DifferenceInPercent)}>{coin.day90DifferenceInPercent || coin.day90DifferenceInPercent === 0 ? `${substringNegativePercent(coin.day90DifferenceInPercent)}%` : '-'}</span>
                    </td>
                    <td>
                        <div className={style.holdings}>
                            <span className={style.holdingsBalance}>${formatedNumber(coin.balanceInUsdt)}</span>
                            <span className={style.holdingsPrice}>{formatedNumber(coin.balance)} {coin.coin}</span>
                        </div>
                    </td>
                    <td>
                        <div className={style.allProfit}>
                            <span
                                className={style.allProfitBalance}>
                                {determineStateBalance(coin.allTimeDifferenceInUsdt)}
                                &nbsp;
                                ${formatedNumber(coin.allTimeDifferenceInUsdt)}
                            </span>
                            <span
                                className={getBalanceClass(coin.allTimeDifferenceInUsdt)}>{coin.allTimeDifferenceInPercent}%</span>
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default PortfolioTable;