import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/Login/Login";
import RouteWrapper from "./components/routeWrapper/routeWrapper";
import './App.css';
import { useSelector } from "react-redux";
import { selectTheme } from "./redux/theme/themeSlice";
import BalanceWrapper from "./components/Balance/BalanceWrapper";
import ApiStatusWrapper from "./components/ApiStatus/ApiStatusWrapper";
import WalletWrapper from "./components/WalletWrapper/WalletWrapper";
import PortfolioWrapper from "./components/PortfolioWrapper/PortfolioWrapper";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Register from "./components/Register/Register";
import {ConfigProvider} from "antd";
import {darkAlgorithm, lightAlgorithm} from "./common/helpers/themeConfig";
import Subscription from "./components/Subscription/Subscription";
import Profile from "./components/Profile/Profile";

function App(): React.ReactElement {
    const selectedTheme = useSelector(selectTheme);
    const themeApp = selectedTheme === 'light' ? 'lightApp' : 'darkApp';
    const antStyle = selectedTheme === 'dark' ? darkAlgorithm : lightAlgorithm;

    return (
      <ConfigProvider theme={antStyle}>
        <div className={`App ${themeApp}`}>
            <Routes>
                <Route path='/auth/login' element={<Login />} />
                <Route path='/auth/register' element={<Register />} />
                <Route element={<ProtectedRoute />}>
                    <Route path='/spreads' element={<RouteWrapper />} />
                    <Route path='/dex' element={<RouteWrapper />} />
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/wallet' element={<WalletWrapper />} />
                    <Route path='/portfolio' element={<PortfolioWrapper />} />
                    <Route path='/balance' element={<BalanceWrapper />} />
                    <Route path='/api-status' element={<ApiStatusWrapper />} />
                    <Route path='/' element={<Navigate to='/spreads' />} />
                </Route>
                <Route path={'/subscription'} element={<Subscription />} />
            </Routes>
        </div>
      </ConfigProvider>
    );
}

export default App;


// import React, {useEffect} from 'react';
// import {getToken} from "./common/auth/auth";
// import {Routes, Route, useNavigate, NavigateFunction} from "react-router-dom";
// import Login from "./components/Login/Login";
// import RouteWrapper from "./components/routeWrapper/routeWrapper";
// import './App.css';
// import {useSelector} from "react-redux";
// import {selectTheme} from "./redux/theme/themeSlice";
// import {selectSpreadsError} from "./redux/spreads/spreadsSlice";
// import BalanceWrapper from "./components/Balance/BalanceWrapper";
// import ApiStatusWrapper from "./components/ApiStatus/ApiStatusWrapper";
// import WalletWrapper from "./components/WalletWrapper/WalletWrapper";
// import PortfolioWrapper from "./components/PortfolioWrapper/PortfolioWrapper";
//
// function App(): React.ReactElement {
//     const isAuthenticated: boolean = !!getToken();
//     const navigate: NavigateFunction = useNavigate();
//     const theme: string = useSelector(selectTheme);
//     const spreadsError = useSelector(selectSpreadsError);
//     const themeApp: string = theme === 'light' ? 'lightApp' : 'darkApp';
//
//     useEffect(() => {
//         if (!isAuthenticated || spreadsError === 'Request failed with status code 401') {
//             console.log('navigate to login');
//             console.log('isAuthenticated', isAuthenticated);
//             console.log('spreadsError', spreadsError)
//             navigate('/auth/login');
//         } else {
//             console.log('navigate to spreads');
//             console.log('isAuthenticated', isAuthenticated);
//             console.log('spreadsError', spreadsError)
//             const pathAfterSlash = window.location.pathname.substring(1); // Получаем часть URL после слеша
//             if (pathAfterSlash === 'wallet' || pathAfterSlash === 'api-status' || pathAfterSlash === 'portfolio' || pathAfterSlash === 'balance') {
//                 // Если в URL после слеша идет "balance", не перенаправляем
//                 return;
//             }
//             navigate('/spreads');
//         }
//     }, [isAuthenticated, navigate, spreadsError]);
//
//     const accessComponent: React.ReactElement = isAuthenticated ? <RouteWrapper/> : <Login/>;
//     const walletComponent: React.ReactElement = isAuthenticated ? <WalletWrapper/> : <Login/>
//     const portfolioComponent: React.ReactElement = isAuthenticated ? <PortfolioWrapper/> : <Login/>
//     const balanceComponent: React.ReactElement = isAuthenticated ? <BalanceWrapper/> : <Login/>;
//     const apiStatusComponent: React.ReactElement = isAuthenticated ? <ApiStatusWrapper/> : <Login/>;
//
//     return (
//         <div className={`App ${themeApp}`}>
//             <Routes>
//                 <Route path='/spreads' element={accessComponent}/>
//                 <Route path='/wallet' element={walletComponent}/>
//                 <Route path='/portfolio' element={portfolioComponent}/>
//                 <Route path='/balance' element={balanceComponent}/>
//                 <Route path='/api-status' element={apiStatusComponent}/>
//                 <Route path='/auth/login' element={<Login/>}/>
//             </Routes>
//         </div>
//     );
// }
//
// export default App;