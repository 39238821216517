import React from 'react';
import {generateId} from "../../common/helpers/helpers";
import style from "../../components/SpreadTable/SpreadTable.module.scss";

interface TotalIncomeProps {
    spread: {
        totalIncome: string[]
    }
}

const TotalIncome: React.FC<TotalIncomeProps> = ({spread}) => {
    return (
        <td>{spread.totalIncome.map((totalIncome) => (
            <div key={generateId()} className={style.lastSpread}>
                <div>{totalIncome}</div>
            </div>
        ))}</td>
    );
};

export default TotalIncome;