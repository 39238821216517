import React from 'react';

import style from './Subscription.module.scss';
import {ghost} from "../../common/helpers/imagesImport";

const Subscription = () => {
  return (
    <div className={style.Subscription}>
      <img src={ghost} alt=""/>
      <h1>Subscription has expired</h1>
    </div>
  );
};

export default Subscription;