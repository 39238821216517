import React from 'react';
import {generateId} from "../../common/helpers/helpers";
import style from "../../components/SpreadTable/SpreadTable.module.scss";

interface UpdatedProps {
    spread: {
        updated: string[]
    }
}

const Updated: React.FC<UpdatedProps> = ({spread}) => {
    return (
        <td>{spread.updated.map((value) => (
            <div key={generateId()} className={style.lastSpread}>
                <div>{value}</div>
            </div>
        ))}</td>
    );
};

export default Updated;