import React from 'react';
import {generateId} from "../../common/helpers/helpers";
import style from "../../components/SpreadTable/SpreadTable.module.scss";
import Tooltip from "../../common/tooltip/Tooltip";
import {commissionHint} from "../../common/helpers/imagesImport";

interface CommisionProps {
    spread: {
        commission: {
          commissionValue: string;
          checkCommission?: boolean
        }[]
    }
}

const Commission: React.FC<CommisionProps> = ({spread}): React.ReactElement => {
    return (
        <td>{spread.commission.map((commission) => (
            <div key={generateId()} className={`${style.commission} ${style.lastSpread}`}>
                <div className={style.commission}>{commission.commissionValue}</div>
                {commission.checkCommission &&
                    <Tooltip content="Check withdraw commission" direction="top">
                      <img className={style.commissionLogo} src={commissionHint} alt='Check withdraw commission'/>
                    </Tooltip>
                }
            </div>
        ))}</td>
    );
};

export default Commission;