import {PuffLoader} from "react-spinners";
import style from "./Loader.module.scss";

const Loader = () => {
  return (
    <div className={style.Loader}>
      <PuffLoader color={'purple'} />
    </div>
  );
};

export default Loader;