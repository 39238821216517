import React from 'react';

const Head: React.FC = () => {
  const itsDex = window.location.pathname.includes('dex');
  return (
        <thead>
        <tr>
          <th>Currency coin</th>
          <th>Spread</th>
          <th>Buy Market</th>
          <th>Balance</th>
          <th>Chain</th>
          <th>Commission</th>
          <th>Sell Market</th>
          {!itsDex ? <th>Buy Price</th> : null}
          {!itsDex ? <th>Income</th> : null}
          <th>Best Buy price</th>
          <th>Best Income</th>
          <th>Created</th>
          <th>Updated</th>
        </tr>
        </thead>
  );
};

export default Head;