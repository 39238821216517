import React, {ReactNode, useState} from "react";
import "./Tooltip.scss";

interface TooltipProps {
    children: ReactNode;
    content: ReactNode;
    direction?: string;
    delay?: number;
    isTable?: boolean;
}

const Tooltip = (props: TooltipProps): React.ReactElement => {
    let timeout: NodeJS.Timeout;

    const [active, setActive] = useState(false);

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, props.delay || 400);
    };

    const hideTip = () => {
        clearInterval(timeout);
        setActive(false);
    };

    const tableHintClass = props.isTable
        ? `Tooltip-Tip__Table ${props.direction || "top"}`
        : `Tooltip-Tip ${props.direction || "top"}`;

    return (
        <div
            className='Tooltip-Wrapper'
            onMouseEnter={showTip}
            onMouseLeave={hideTip}
        >
            {props.children}
            {active && props.content && (
                <div className={tableHintClass}>
                    {props.content}
                </div>
            )}
        </div>
    );
};

export default Tooltip;