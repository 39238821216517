import {useNavigate} from "react-router-dom";
import {Button, Input} from 'antd';
import style from './Register.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {preRegisterAsync, selectAuthStatus, registerAsync} from "../../redux/auth/authSlice";
import {useState} from "react";
import {siteLogo} from "../../common/helpers/imagesImport";
import Loader from "../../common/loader/Loader";
import {isValidEmail} from "../../common/helpers/auth";
import {NotificationManager} from "react-notifications";
import {selectTheme} from "../../redux/theme/themeSlice";

const Register = () => {
  const dispatch = useDispatch();
  const authStatus = useSelector(selectAuthStatus);
  const navigate = useNavigate();
  const theme = useSelector(selectTheme);
  const [email, setEmail] = useState('');
  const [password, setPass] = useState('');
  const [lastStage, setLastStage] = useState(false);
  const [code, setCode] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);

  const onFinish = async () => {
    if (!isValidEmail(email)) {
      setIsEmailValid(false);
      NotificationManager.error('Invalid Email');
      return
    }

    try {
      if (!lastStage) {
        const resp = await dispatch(preRegisterAsync({email, code}));
        if (resp.payload) {
          setLastStage(true);
        }
      } else {
        await dispatch(registerAsync({email, password, code}));
        routeToLogin();
      }

    } catch (error) {
      console.error('Register failed', error);
    }
  };

  const routeToLogin = () => {
    navigate('/auth/login')
  }

  const handleEmailChange = (value) => {
    setEmail(value);
    setIsEmailValid(true);
  };

  if (authStatus === 'loading') return <Loader />

  return (
    <div className={style.wrapper}>
      <div className={style.logo}>
        <img src={siteLogo} alt=""/>
        <h1>CRYPTO SPACE</h1>
      </div>

      <div className={`${style.auth} ${theme}`}>
        <span className={style.title}>SIGN UP</span>

        <div className={style.inputs}>
          <Input
            status={isEmailValid ? '' : 'error'}
            onChange={(e) => handleEmailChange(e.target.value)}
            value={email}
            size={'large'}
            type={'email'}
            disabled={lastStage}
            placeholder="Email"
          ></Input>
          {!lastStage && (
            <Input
              onChange={(e) => setCode(e.target.value)}
              value={code}
              size={'large'}
              placeholder="Invite code"
            ></Input>
          )}
          {lastStage && (
            <Input.Password
              onChange={(e) => setPass(e.target.value)}
              value={password}
              size={'large'}
              placeholder="Passowrd">
            </Input.Password>
          )}
        </div>

        <div className={style.buttons}>
          <Button onClick={onFinish} ghost size={'large'}>REGISTER</Button>

          <div className={style.additional}>
            <div className={style.signUp}>
                <span>Have account? </span>
                <span onClick={routeToLogin}>Sign In</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
