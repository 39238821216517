import React from 'react';
import { getToken } from "../../common/auth/auth";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectSpreadsError } from "../../redux/spreads/spreadsSlice";

const ProtectedRoute = () => {
  const isAuthenticated = !!getToken();
  const spreadsError = useSelector(selectSpreadsError);

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }

  if (spreadsError === 'Request failed with status code 401') {
    return <Navigate to="/auth/login" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;