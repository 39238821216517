import {ThemeConfig} from "antd";

export const darkAlgorithm: ThemeConfig = {
  components: {
    Button: {
      colorPrimary: '#4096ff',
      algorithm: true,
    },
    Input: {
      colorPrimary: 'white',
      colorBgContainer: '#333333',
      activeBorderColor: '#4096ff',
      colorTextPlaceholder: 'grey',
      colorText: 'white',
      colorTextDisabled: 'white'
    },
    Dropdown: {
      colorPrimary: 'white',
      colorBgElevated: '#333333',
      colorText: 'white',
    },
  },
  token: {
    colorIcon: 'white',
    colorIconHover: '#4096ff'
  }
};

export const lightAlgorithm: ThemeConfig = {
  components: {
    Button: {
      colorPrimary: '#333333',
      colorBgContainer: 'black'
    },
    Input: {
      colorPrimary: 'white',
      colorTextPlaceholder: 'grey',
      colorBorder: 'black',
      colorText: 'black',
      colorTextDisabled: 'black',
    },
  },
  token: {
    colorIcon: 'black',
    colorIconHover: '#4096ff'
  }
};